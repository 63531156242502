//vendor
require("@fancyapps/fancybox");
import * as lottie from 'lottie-web/build/player/lottie_svg';
const Rellax = require('rellax');
import * as AOS from 'aos/dist/aos';

//Data
import * as lottieDroneData from './lottie-drone-data.json';

//Polyfills
import 'svgxuse/svgxuse.js';


export default class APP {
	constructor() {

		//Handlers
		$(document).on('click', '.btn--toggle-nav', this.navToggle);
		$(document).on('click', '.accordion__toggle', this.toggleAccordion);

		this.default();

	}

	default() {

		$('[data-fancybox]').fancybox({
			toolbar  : false,
			smallBtn : true,
			iframe : {
				preload : false
			},
			afterShow: function( instance, current ) {

			}
		})		

		//Unorphanize
		var textElements = document.querySelectorAll( 'p:not(.excluded)' );
		for ( var i = 0; i < textElements.length; i++ ) {
			var textElement = textElements[ i ];
			textElement.innerHTML = textElement.innerHTML.replace(/\s([^\s<]+)\s*$/,'&nbsp;$1');
		}


		// Add smooth scrolling to all links
		$("a.scrollto").on('click', function(event) {

			if (this.hash !== "") {
				event.preventDefault();
		
				var hash = this.hash;
		
				$('html, body').animate({
				scrollTop: $(hash).offset().top - 100
				}, 1000, function() {
		
				//window.location.hash = hash;
				});
			}
		});

	}

	navToggle(e) {

		e.preventDefault();

		var $_nav = $('#mainnav'),
			nav_is_open = $_nav.hasClass('is-open');

		if (nav_is_open) {
			$_nav.removeClass('is-open');
			$('body').removeClass('nav-is-open');
			APP.lock_scroll(false);
		} else {
			$_nav.addClass('is-open');
			$('body').addClass('nav-is-open');
			APP.lock_scroll(true);
		}

	}

	toggleAccordion(e) {

		e.preventDefault();

		var $_toggle = $(this),
			$_question = $_toggle.parents('.accordion'),
			question_is_opened = $_question.hasClass('active');

		if(question_is_opened) {
			$_question.removeClass('active');
		} else {
			$_question.addClass('active');
		}

	}

	lock_scroll(state) {
		if (state == true) {
			$('body, html').width($('body').width())
				.height($('body').height())
				.css('overflow', 'hidden');

		} else {
			$('body, html').removeAttr('style');
			$('#content').removeAttr('style');
		}
	}

};

var app = new APP();


const lottieDrone = document.getElementById('lottie-drone');

lottie.loadAnimation({
	container: lottieDrone,
	loop: true,
	autoplay: true,
	animationData: lottieDroneData,
});

lottie.setSpeed('.5');

var rellax = new Rellax('.rellax');

AOS.init({
	once: true,
});